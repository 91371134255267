.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

@media screen and (max-width: 678px) {
  .container {
    padding: 15px;
  }
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

button.submit {
  background-color: #B6D7A8;
  border: 1px solid rgb(125,180,102);
  padding: 6px 20px;
  max-width: 200px;
  width: 100%;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: black;
  box-shadow: -1px 1px 3px 2px rgb(0 0 0 / 20%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}

.button_text:first-child {
  font-size: 20px;
}

@media screen and (max-width: 678px) {
  .button.submit {
    max-width: 150px;
  }

  .button_text:first-child {
    font-size: 18px;
  }
}

.container button.submit:hover {
  opacity: 0.7;
}

.header {
  margin-bottom: 20px;
  color: #595959;
}

.header.steps {
  font-weight: bold;
  color: black;
  font-size: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 678px) {
  .header.steps {
    font-size: 18px;
  }
}

.header_line {
  margin-bottom: 10px;
}

.translate {
  font-size: 130%;
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 678px) {
  .translate {
    font-size: 120%;
  }
}

.form_wrap form {
  display: flex;
  flex-direction: column;
}

.checkbox_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
  padding-top: 20px;
}

.checkbox_line {
  margin-bottom: 15px;
  padding-right: 10px;
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.checkbox_line label {
  display: flex;
  align-items: center;
}


.checkbox_line .checkbox_wrap {
  height: 100%;
}

.checkbox_line .symptom_text {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  color: black;
}

.form_wrap form .checkbox {
  display: block;
  width: 23px;
  height: 23px;
  padding: 8px 12px;
  margin-right: 15px;
  
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #D1D1D1;
}

.submit {
  margin-left: auto;
}

.button_divider {
  margin-left: 5px;
  margin-right: 5px;
}

