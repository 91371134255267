.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

@media screen and (max-width: 678px) {
  .container {
    padding: 15px;
  }
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

.btn_wrap {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.submit_btn_wrap { 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer_wrap {
  font-size: 25px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.container button, .submit {
  background-color: transparent;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  color: gray;
  cursor: pointer;
}

.container .submit {
  position: relative;
  background-color: #B6D7A8;
  border: 1px solid rgb(125,180,102);
  padding: 6px 20px;
  max-width: 200px;
  width: 100%;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: black;
  box-shadow: -1px 1px 3px 2px rgb(0 0 0 / 20%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.container .submit .button_text:first-child {
  font-size: 20px;
}

@media screen and (max-width: 678px) {
  .container .submit {
    max-width: 150px;
  }

  .container .submit .button_text:first-child {
    font-size: 18px;
  }
}

.container button:hover {
  color: #3898EC;
}

.container .submit:hover {
  color: black;
  opacity: 0.7;
}

.button_divider {
  margin-left: 7px;
  margin-right: 7px;
}

.container .submit.button__loading {
  padding-right: 50px;
}

.button__loading::after {
  content: "";
  position: absolute;
  width: 23px;
  height: 23px;
  top: 0;
  right: 8px;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #c91859e1;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.header {
  margin-bottom: 20px;
  color: #595959;
}

.repeat_msg {
  font-weight: 600;
  color:#4285f4;
}

.gray_color {
  color: #595959;
}

.header.steps {
  font-weight: bold;
  color: black;
  font-size: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 678px) {
  .header.steps {
    font-size: 18px;
  }
}

a {
  color: #595959;
  text-decoration: underline;
}

.translate a {
  color: #DCA10D;
}

.translate a:visited {
  color: #DCA10D;
}

.header_line {
  margin-bottom: 10px;
}

.translate {
  font-size: 130%;
  font-weight: bold;
  color: black;
}

.err_msg, .err_msg .translate {
  color: #E06666;
}

.repeat_msg .translate {
  color:#4285f4;
}

@media screen and (max-width: 678px) {
  .translate {
    font-size: 120%;
  }
}

.translate_text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
}

.form_wrap form label{
  margin-bottom: 15px;
}

.list {
  line-height: 1.3;
  padding-left: 0;
  list-style: none;
}

.list li{
  margin-bottom: 20px;
  font-weight: 400;
  color: #595959;
}

.list li::marker {
  font-size: 130%;
  font-weight: bold;
  color: black;
}


.start_btn {
  max-width: 180px;
}
