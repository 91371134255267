.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

@media screen and (max-width: 678px) {
  .container {
    padding: 15px;
  }
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

.container button {
  background-color: #B6D7A8;
  border: 1px solid rgb(125,180,102);
  padding: 6px 20px;
  max-width: 200px;
  width: 100%;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: black;
  box-shadow: -1px 1px 3px 2px rgb(0 0 0 / 20%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}

.button_text:first-child {
  font-size: 20px;
}

@media screen and (max-width: 678px) {
  .container button {
    max-width: 150px;
  }

  .button_text:first-child {
    font-size: 18px;
  }
}

.container button:hover {
  opacity: 0.7;
}

.button_divider {
  margin-left: 5px;
  margin-right: 5px;
}

.header {
  margin-bottom: 20px;
  color: #595959;
}

.header.steps {
  font-weight: bold;
  color: black;
  font-size: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 678px) {
  .header.steps {
    font-size: 18px;
  }
}

.header_line {
  margin-bottom: 10px;
}

.translate {
  font-size: 130%;
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 678px) {
  .translate {
    font-size: 120%;
  }
}

.red_color {
  color: red;
}

.err_msg, .err_msg .translate {
  color: #E06666;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form_wrap form label{
  margin-bottom: 20px;
  color: #595959;
}

.form_wrap form label .translate {
  margin-top: 10px;
}

.form_wrap form input {
  display: block;
  width: 100%;
  height: 42px;
  padding: 8px 12px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.form_wrap input:focus {
  border-color: #3898EC;
  outline: 0;
}

.form_wrap input::placeholder {
  color: #333333;
  font-style: italic;
  opacity: 0.7;
}

.form_wrap input::-webkit-outer-spin-button,
.form_wrap input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form_wrap input[type=number] {
  -moz-appearance: textfield;
}

