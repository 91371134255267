.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

@media screen and (max-width: 678px) {
  .container {
    padding: 15px;
  }
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

.header {
  margin-bottom: 20px;
}

.header_line {
  margin-bottom: 10px;
}

.translate {
  font-size: 130%;
  font-weight: 600;
}

@media screen and (max-width: 678px) {
  .translate {
    font-size: 120%;
  }
}

p.translate {
  font-weight: 400;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
}

.form_wrap form label{
  margin-bottom: 15px;
}

.container p {
  margin-bottom: 10px;
}