.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 50px;
}

@media screen and (max-width: 678px) {
  .container {
    padding: 15px;
  }
}

.form_wrap {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  font-family: Montserrat, sans-serif;
}

button.submit {
  background-color: #B6D7A8;
  border: 1px solid rgb(125,180,102);
  padding: 6px 20px;
  max-width: 200px;
  width: 100%;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: black;
  box-shadow: -1px 1px 3px 2px rgb(0 0 0 / 20%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}

.button_text:first-child {
  font-size: 20px;
}

@media screen and (max-width: 678px) {
  button.submit {
    max-width: 150px;
  }

  .button_text:first-child {
    font-size: 18px;
  }
}

.container button.submit:hover {
  opacity: 0.7;
}

.header {
  margin-bottom: 20px;
  color: #595959;
}

.header.steps {
  font-weight: bold;
  color: black;
  font-size: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 678px) {
  .header.steps {
    font-size: 18px;
  }
}

.header_line {
  margin-bottom: 10px;
}

.translate {
  font-size: 130%;
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 678px) {
  .translate {
    font-size: 120%;
  }
}

.radio_text .translate {
  line-height: 1;
}

.form_wrap form {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
}

.radio_container {
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
}

.radio_line {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-right: 10px;
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
}

.radio_line label {
  display: flex;
  align-items: center;
}

.radio_line .radio_wrap {
  height: 100%;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.radio_line .radio_wrap  .checkmark {
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  background-color: #ffffff;
  border: 1px solid #D1D1D1;

}

.radio_line .radio_wrap:hover input ~ .checkmark {
  background-color: #ccc;
}

.radio_line .radio_wrap input:checked ~ .checkmark {
  background-color: #2196F3;
}

.radio_line .radio_wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio_line .radio_wrap input:checked ~ .checkmark:after {
  display: block;
}

.radio_line .radio_wrap .checkmark:after {
  left: 8px;
  top: 3px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3.5px 3.5px 0;
  transform: rotate(37deg);
}

.radio_line .radio_text {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  color: black;
}

.radio_container .radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form_wrap form .radio {
  display: block;
  width: 18px;
  height: 18px;
  padding: 8px 12px;
  margin-right: 15px;
  
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #D1D1D1;
}

.submit {
  margin-left: auto;
}

.button_divider {
  margin-left: 5px;
  margin-right: 5px;
}

.form_wrap form input[type=number] {
  display: block;
  width: 100%;
  max-width: 320px;
  height: 42px;
  padding: 8px 12px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.form_wrap input[type=number]:focus {
  border-color: #3898EC;
  outline: 0;
}

.form_wrap input[type=number]::placeholder {
  color: #333333;
  font-style: italic;
  opacity: 0.7;
}

.form_wrap input[type=number]::-webkit-outer-spin-button,
.form_wrap input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form_wrap input[type=number] {
  -moz-appearance: textfield;
}

.err_msg, .err_msg .translate {
  color: #E06666;
}

