@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap');

*,
html,
body,
body p {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  font-family: Montserrat, sans-serif;
  
}

body {
  font-size: 16px;
}

@media screen and (max-width: 678px) {
  body {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.navbar {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 1280px;
}

.navigation .brand {
  max-width: 150px;
  display: flex;
  align-items: center;
}

.nav_menu {
  display: flex;
  justify-content: space-between;
  max-width: 170px;
  width: 100%;
}

.nav_menu .nav_link {
  padding: 5px 0px;
  color: #595959;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}

.nav_menu .nav_link:visited {
  color: #333333;
}

@media screen and (max-width: 500px) {
  .navigation {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .nav_menu {
    margin-top: 30px;
  }
}